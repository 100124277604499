// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import About from './Components/About';
import Services from './Components/Services';
 import TechnologiesPage from './Components/Technologies';
 import Career from './Components/Career';// import MorePage from './Components/MorePage';
 import ContactPage from './Components/ContactUs';
import JobDetail from './Components/Career/JobDetail';
import JobApplication from './Components/Career/JobApplication';
import BlogPost from './Components/home/BlogPost';
// import CustomServices from './Components/CustomServices';
// import Cloudservices from './Components/Cloudservices';
// import ITConsulting from './Components/ITConsulting';
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/technologies" element={<TechnologiesPage />} />
         <Route path="/services" element={<Services />} />
         <Route path="/career" element={<Career />} />
         <Route path="/contact-us" element={<ContactPage />} /> 
         <Route path="/job/:id" element={<JobDetail />} />
        <Route path="/apply/:id" element={<JobApplication />} />{/*
        <Route path="/service2" element={<Cloudservices />} />
        <Route path="/service3" element={<ITConsulting />} />
        
        
        <Route path="/more" element={<MorePage />} />

        <Route path="/contact-us" element={<ContactPage />} /> 
        
         */}
        <Route path="/blog/:id" element={<BlogPost/>}/>
         
       
      </Routes>
    </Router>
  );
};

export default App;
