import React from 'react';

const About = () => {
  return (
    <div className="min-h-screen flex flex-col font-sans text-gray-900">
      <main className="flex-grow">
        <AboutBanner />
        <AboutContent />
      </main>
    </div>
  );
};

const AboutBanner = () => {
  return (
    <div className="bg-green-300 text-white py-20 sm:py-28 text-center">
      <h1 className="text-4xl sm:text-5xl font-extrabold mb-6 px-4">
        About Us
      </h1>
      <button className="bg-white text-black px-8 sm:px-10 py-3 sm:py-4 rounded-full font-semibold shadow-md hover:bg-gray-100 transition duration-300 transform hover:scale-105">
        Learn More About Us
      </button>
    </div>
  );
};

const AboutContent = () => {
  return (
    <div className="flex justify-center px-4 sm:px-6">
      <section className="z-10 self-center px-6 sm:px-12 py-12 -mt-14 w-full bg-white rounded-3xl max-w-[992px] shadow-lg">
        <div className="flex flex-col sm:flex-row gap-5">
          <div className="w-full sm:w-[26%]">
            <h2 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold text-blue-400 leading-tight sm:leading-[1.2]">
              Our Story
            </h2>
          </div>
          <div className="w-full sm:w-[74%] mt-6 sm:mt-0">
            <div className="flex flex-col w-full text-lg sm:text-xl text-zinc-700">
              <p className="mb-4">
                We are a team of passionate individuals dedicated to creating innovative solutions that make a difference in people's lives.
              </p>
              <p className="mb-4">
                Our mission is to leverage cutting-edge technology to solve complex problems and drive positive change in various industries.
              </p>
              <p>
                With a focus on continuous learning and growth, we strive to stay at the forefront of technological advancements and deliver exceptional value to our clients and partners.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;