import React from 'react';
import { Shield, BarChart2, Code, Smartphone, Search, Cloud } from 'lucide-react';
import image from './image.png';
const TechnologyItem = ({ icon: Icon, title, description, color }) => {
  return (
    <div className="flex items-start space-x-4 p-4 bg-white rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:bg-opacity-90">
      <div className={`p-3 rounded-full ${color} bg-opacity-20 animate-pulse`}>
        <Icon className={`text-3xl ${color} flex-shrink-0 transition-transform duration-500 transform hover:rotate-12 hover:scale-110`} />
      </div>
      <div>
        <h3 className="text-xl font-semibold mb-2 text-gray-800">{title}</h3>
        <p className="text-sm text-gray-600">{description}</p>
      </div>
    </div>
  )
};

const SplitLayoutWithImage = () => {
  const technologies = [
    { icon: Shield, title: "Cyber Security", description: "Protect your business from cyber threats", color: "text-orange-500" },
    { icon: BarChart2, title: "Data Analytics", description: "Gain actionable insights for business growth", color: "text-yellow-500" },
    { icon: Code, title: "Web Development", description: "Create custom websites for your brand", color: "text-green-500" },
    { icon: Smartphone, title: "Apps Development", description: "Develop engaging mobile apps for iOS and Android", color: "text-orange-500" },
    { icon: Cloud, title: "Cloud Computing", description: "Reduce costs with cloud infrastructure management", color: "text-yellow-500" },
    { icon: Search, title: "SEO Optimization", description: "Improve online visibility and organic traffic", color: "text-green-500" },
  ];

  return (
    <div className="container mx-auto px-4 py-16 bg-gray-50">
      <h2 className="text-4xl font-bold text-center text-green-500 mb-12 animate-fadeIn">
        Our Technologies
      </h2>
      <div className="flex flex-col md:flex-row justify-between items-center space-y-8 md:space-y-0 md:space-x-8">
        <div className="w-full md:w-1/3 space-y-6 animate-slideInLeft">
          {technologies.slice(0, 3).map((tech, index) => (
            <TechnologyItem key={index} {...tech} />
          ))}
        </div>
        <div className="w-full md:w-1/3 flex justify-center items-center ">
          <img
            src={image}
            alt="Technology illustration"
            className="rounded-full shadow-2xl transform hover:scale-105 transition-transform duration-300 border-4 border-black-300"
          />
        </div>
        <div className="w-full md:w-1/3 space-y-6 animate-slideInRight">
          {technologies.slice(3).map((tech, index) => (
            <TechnologyItem key={index + 3} {...tech} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SplitLayoutWithImage;