import React from 'react';
import { Globe, Database, Shield, Cpu, Users, ChevronRight, Code, Cloud, Phone, Mail, MapPin, Lightbulb, Zap, BarChart } from 'lucide-react';
import Header from './Services/Header';
import Welcome from './Services/Welcome';
import Footer from './home/Footer';
import Partnes from './home/Partners';

const ServiceSection = ({ title, description, items, icon: Icon, index }) => (
    <div
      className={`mb-8 bg-white bg-opacity-10 rounded-lg p-6 transition-all duration-300 
        shadow-lg hover:shadow-xl hover:bg-opacity-20 animate-fadeInUp`}
      style={{ animationDelay: `${index * 0.1}s` }}
    >
      <h3 className="text-2xl font-bold mb-2 flex items-center text-yellow-500">
        <Icon className="mr-3" size={28} />
        {title}
      </h3>
      <p className="mb-4 text-gray-500">{description}</p>
      <ul className="space-y-3 text-orange-700">
        {items.map((item, index) => (
          <li key={index} className="flex items-start">
            <ChevronRight className="mr-2 mt-1 flex-shrink-0 text-yellow-300" size={16} />
            <span>{item}</span>
          </li>
        ))}
      </ul>
    </div>
  );
  

const TestimonialCard = ({ quote, author, company }) => (
  <div className="bg-white bg-opacity-5 p-6 rounded-lg shadow-lg">
    <p className="text-lg mb-4 italic">"{quote}"</p>
    <p className="font-semibold">{author}</p>
    <p className="text-sm text-gray-400">{company}</p>
  </div>
);

const RavuruTechServices = () => {
  return (
    <div className="bg-gradient-to-r  min-h-screen text-white">
      <Header/>
      <Welcome/>
      <main className="max-w-6xl mx-auto px-4 py-12">
        <section id="services" className="mb-16">
          <h2 className="text-3xl font-bold text-green-500 mb-8 text-center animate-fadeInDown">Our Comprehensive Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <ServiceSection
              title="IT Strategy & Consulting"
              icon={Globe}
              description="Craft the perfect IT strategy for your business with expert guidance."
              items={[
                "Assess your current setup",
                "Tailor a roadmap to success",
                "Navigate the tech landscape",
                "Become your trusted partner"
              ]}
              index={0}
            />
            <ServiceSection
              title="Business Consulting"
              icon={Users}
              description="Empower your business to thrive in the digital age with our comprehensive consulting."
              items={[
                "Analyze your IT landscape",
                "Craft a tailored roadmap",
                "Stay ahead of trends",
                "Foster clear communication"
              ]}
              index={1}
            />
            <ServiceSection
              title="R&D (Research & Development)"
              icon={Cpu}
              description="Push the boundaries of technology with our cutting-edge R&D team."
              items={[
                "Conjure groundbreaking solutions",
                "Craft a future-proof vision",
                "Champion creativity",
                "Evolve constantly"
              ]}
              index={2}
            />
            <ServiceSection
              title="Web Development"
              icon={Code}
              description="Create stunning, functional websites that drive results."
              items={[
                "Responsive design",
                "E-commerce solutions",
                "Custom web applications",
                "SEO optimization"
              ]}
              index={3}
            />
            <ServiceSection
              title="Cloud Solutions"
              icon={Cloud}
              description="Harness the power of the cloud for scalability and efficiency."
              items={[
                "Cloud migration",
                "Hybrid cloud setups",
                "Cloud security",
                "Managed cloud services"
              ]}
              index={4}
            />
            <ServiceSection
              title="AI-Powered IT Staffing"
              icon={Shield}
              description="Find the perfect IT talent with our innovative AI platform."
              items={[
                "Behavioral data analysis",
                "Skill-based matching",
                "Diverse team building",
                "Continuous learning profiles"
              ]}
              index={5}
            />
          </div>
        </section>

        <section id="about" className="mb-16 bg-black bg-opacity-30 rounded-lg p-8 animate-fadeIn">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <p className="mb-4">At RAYURU TECH, we're passionate about leveraging technology to drive business success. With our team of expert consultants and cutting-edge solutions, we help organizations of all sizes navigate the complex world of IT and emerge stronger, more efficient, and ready for the future.</p>
              <p>Our commitment to innovation, coupled with our deep industry expertise, allows us to deliver tailored solutions that address your unique challenges and unlock your full potential.</p>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col items-center animate-pulse">
                <Lightbulb size={40} className="text-yellow-300 mb-2" />
                <h3 className="font-semibold mb-1">Innovative</h3>
                <p className="text-center text-sm">Cutting-edge solutions</p>
              </div>
              <div className="flex flex-col items-center animate-pulse" style={{ animationDelay: '0.2s' }}>
                <Zap size={40} className="text-yellow-300 mb-2" />
                <h3 className="font-semibold mb-1">Agile</h3>
                <p className="text-center text-sm">Adaptable to change</p>
              </div>
              <div className="flex flex-col items-center animate-pulse" style={{ animationDelay: '0.4s' }}>
                <Users size={40} className="text-yellow-300 mb-2" />
                <h3 className="font-semibold mb-1">Collaborative</h3>
                <p className="text-center text-sm">Partnership-focused</p>
              </div>
              <div className="flex flex-col items-center animate-pulse" style={{ animationDelay: '0.6s' }}>
                <BarChart size={40} className="text-yellow-300 mb-2" />
                <h3 className="font-semibold mb-1">Results-Driven</h3>
                <p className="text-center text-sm">Measurable impact</p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Partnes/>
      <Footer/>
    </div>
  );
};

export default RavuruTechServices;