import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Calendar, Clock, User } from 'lucide-react';
import { db } from '../../firebase';
import Comments from './Comments.js';
import Header from './Header.js';
import Footer from './Footer.js';

const BlogPost = () => {
  const [blog, setBlog] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const docRef = doc(db, 'blogs', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setBlog({ id: docSnap.id, ...docSnap.data() });
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching blog post: ', error);
      }
    };

    fetchBlog();
  }, [id]);

  if (!blog) return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="text-2xl font-serif text-gray-700"
      >
        Loading...
      </motion.div>
    </div>
  );

  const formatDate = (date) => {
    return new Date(date.toDate()).toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });
  };

  const estimateReadTime = (content) => {
    const wordsPerMinute = 200;
    const wordCount = content.split(/\s+/).length;
    return Math.ceil(wordCount / wordsPerMinute);
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Header />
      <main className="flex-grow">
        <article className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-4xl sm:text-5xl font-bold font-serif text-gray-900 mb-4">
              {blog.title}
            </h1>
            <div className="flex items-center text-gray-600 mb-8">
              <User size={18} className="mr-2" />
              <span className="mr-4">{blog.author || 'Anonymous'}</span>
              <Calendar size={18} className="mr-2" />
              <span className="mr-4">{formatDate(blog.createdAt)}</span>
              <Clock size={18} className="mr-2" />
              <span>{estimateReadTime(blog.content)} min read</span>
            </div>
            {blog.image && (
              <img
                src={blog.image}
                alt={blog.title}
                className="w-full h-64 sm:h-96 object-cover rounded-lg mb-8"
              />
            )}
            <div className="prose prose-lg max-w-none">
              <p className="text-xl text-gray-700 mb-8 leading-relaxed">
                {blog.description || "A captivating journey through the author's thoughts and experiences."}
              </p>
              <div
                dangerouslySetInnerHTML={{ __html: blog.content }}
                className="text-gray-800 leading-relaxed"
              />
            </div>
          </motion.div>
        </article>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-16"
        >
          <Comments blogId={id} />
        </motion.div>
      </main>
      <Footer />
    </div>
  );
};

export default BlogPost;