import React, { useState, useEffect } from 'react';
import { getDocs, collection } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { db } from '../../firebase';
import { FaClock, FaUser, FaArrowRight } from 'react-icons/fa';

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'blogs'));
        const blogData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBlogs(blogData);
      } catch (error) {
        console.error('Error fetching blogs: ', error);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-gray-800 mb-8 text-center">Our Blog</h1>

      <div className="space-y-6">
        {blogs.map((blog) => (
          <div key={blog.id} className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 hover:shadow-xl group">
            <div className="flex items-center p-4">
              <img
                src={blog.image || 'https://via.placeholder.com/100x100'}
                alt={blog.title}
                className="w-24 h-24 object-cover rounded-lg mr-4"
              />
              <div className="flex-grow">
                <h3 className="text-xl font-semibold text-gray-900 mb-2">{blog.title}</h3>
                <p className="text-gray-600 line-clamp-2">{blog.description}</p>
                <div className="flex items-center text-sm text-gray-500 mt-2">
                  <FaUser className="mr-2" />
                  <span className="mr-4">{blog.author || 'Anonymous'}</span>
                  <FaClock className="mr-2" />
                  <span>{new Date(blog.createdAt?.toDate()).toLocaleDateString()}</span>
                </div>
              </div>
              <Link
                to={`/blog/${blog.id}`}
                className="flex items-center justify-center w-10 h-10 bg-indigo-100 text-indigo-600 rounded-full transition-all duration-300 group-hover:bg-indigo-600 group-hover:text-white"
              >
                <FaArrowRight />
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogList;
