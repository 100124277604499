import React from 'react';
import { FaCogs, FaLightbulb, FaUserTie, FaCheckCircle } from 'react-icons/fa';

const WhyWeChooseUs = () => {
  return (
    <section id="why-choose-us" className="py-16  text-center">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold mb-12 text-gray-800">Why Choose Us?</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="bg-white rounded-lg p-6 shadow-lg transform transition duration-300 hover:-translate-y-2">
            <FaCogs className="text-5xl text-green-500 mb-4 mx-auto" />
            <h3 className="text-2xl font-semibold text-yellow-400 mb-4">Expertise and Experience</h3>
            <p className="text-gray-600">Our team has years of experience in the industry, delivering top-notch solutions that meet your needs.</p>
          </div>
          <div className="bg-white rounded-lg p-6 shadow-lg transform transition duration-300 hover:-translate-y-2">
            <FaLightbulb className="text-5xl text-green-500 mb-4 mx-auto" />
            <h3 className="text-2xl font-semibold text-yellow-400 mb-4">Innovative Solutions</h3>
            <p className="text-gray-600">We are committed to innovation, offering cutting-edge solutions to help you stay ahead of the competition.</p>
          </div>
          <div className="bg-white rounded-lg p-6 shadow-lg transform transition duration-300 hover:-translate-y-2">
            <FaUserTie className="text-5xl text-green-500 mb-4 mx-auto" />
            <h3 className="text-2xl font-semibold text-yellow-400 mb-4">Customer-Centric Approach</h3>
            <p className="text-gray-600">Your satisfaction is our priority. We tailor our services to meet your unique requirements and ensure your success.</p>
          </div>
          <div className="bg-white rounded-lg p-6 shadow-lg transform transition duration-300 hover:-translate-y-2">
            <FaCheckCircle className="text-5xl text-green-500 mb-4 mx-auto" />
            <h3 className="text-2xl font-semibold text-yellow-400 mb-4">Proven Track Record</h3>
            <p className="text-gray-600">We have a proven track record of delivering successful projects on time and within budget.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyWeChooseUs;
