import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Card, CardHeader, CardContent, Typography, Box, Avatar, 
  Tabs, Tab, Chip, Accordion, AccordionSummary, AccordionDetails, 
  Grid, Button, Container, useMediaQuery, useTheme
} from '@mui/material';
import { 
  BsDisplay, BsServer, BsCloud, BsShieldCheck, BsLightning, 
  BsPeople, BsChevronDown, BsArrowRight
} from 'react-icons/bs';
import Footer from './home/Footer';
import Header from './Tech/Header';
import Welcome from './Tech/Welcome';
import Tech from './home/Technologies';

const fadeInUp = {
  initial: { opacity: 0, y: 60 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 }
};

const staggerChildren = {
  animate: {
    transition: {
      staggerChildren: 0.1
    }
  }
};

const TechCard = ({ icon: Icon, title, description, isMobile }) => {
  return (
    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
      <Card sx={{
        backgroundColor: 'white',
        color: '#388e3c',
        border: '1px solid #4caf50',
        borderRadius: 2,
        height: '100%',
        display: 'flex',
        flexDirection: isMobile ? 'row' : 'column',
        alignItems: isMobile ? 'center' : 'flex-start',
        transition: 'box-shadow 0.3s ease-in-out',
        '&:hover': {
          boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
        },
      }}>
        <CardHeader
          avatar={<Icon style={{ width: isMobile ? 36 : 48, height: isMobile ? 36 : 48, color: '#ff9800' }} />}
          title={<Typography variant={isMobile ? "h6" : "h5"} sx={{ color: '#388e3c', fontWeight: 700 }}>{title}</Typography>}
        />
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography sx={{ color: '#388e3c', fontSize: isMobile ? '0.9rem' : '1rem' }}>{description}</Typography>
        </CardContent>
      </Card>
    </motion.div>
  );
};

const TeamMember = ({ name, role, imageUrl, isMobile }) => (
  <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
    <Box textAlign="center" sx={{ padding: 2 }}>
      <Avatar 
        src={imageUrl} 
        alt={name} 
        sx={{ 
          width: isMobile ? 96 : 128, 
          height: isMobile ? 96 : 128, 
          margin: '0 auto 16px', 
          boxShadow: '0 4px 12px rgba(0,0,0,0.2)' 
        }} 
      />
      <Typography variant={isMobile ? "subtitle1" : "h6"} sx={{ color: '#388e3c', fontWeight: 700 }}>{name}</Typography>
      <Typography variant="body2" sx={{ color: '#ff9800', fontSize: isMobile ? '0.8rem' : '0.9rem' }}>{role}</Typography>
    </Box>
  </motion.div>
);

const ProjectShowcase = ({ isMobile }) => {
  const [value, setValue] = useState(0);
  const projects = [
    { name: "Mecha Manga Reader", description: "A high-performance manga reading app with AI-powered translations.", image: "/api/placeholder/400/300" },
    { name: "Anime Streaming Platform", description: "A cloud-based streaming service optimized for anime content.", image: "/api/placeholder/400/300" },
    { name: "Waifu AI Assistant", description: "An AI-powered virtual assistant with anime-inspired personality.", image: "/api/placeholder/400/300" },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs 
        value={value} 
        onChange={handleChange} 
        centered 
        variant={isMobile ? "scrollable" : "standard"}
        scrollButtons="auto"
        sx={{
          '.MuiTab-root': {
            color: '#388e3c',
            fontWeight: 600,
            fontSize: isMobile ? '0.9rem' : '1rem',
            '&.Mui-selected': {
              color: '#ff9800',
            },
          },
          '.MuiTabs-indicator': {
            backgroundColor: '#ff9800',
          },
        }}
      >
        {projects.map((project, index) => (
          <Tab key={index} label={project.name} />
        ))}
      </Tabs>
      <AnimatePresence mode="wait">
        {projects.map((project, index) => (
          value === index && (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.3 }}
            >
              <Box padding={isMobile ? 2 : 3}>
                <Card sx={{ borderRadius: 2, boxShadow: '0 4px 12px rgba(0,0,0,0.2)' }}>
                  <CardHeader 
                    title={<Typography sx={{ color: '#388e3c', fontWeight: 700, fontSize: isMobile ? '1.2rem' : '1.5rem' }}>{project.name}</Typography>} 
                  />
                  <CardContent>
                    <Box 
                      component="img" 
                      src={project.image} 
                      alt={project.name} 
                      sx={{ 
                        width: '100%', 
                        height: isMobile ? 150 : 200, 
                        objectFit: 'cover', 
                        marginBottom: 2,
                        borderRadius: 1, 
                      }} 
                    />
                    <Typography sx={{ color: '#388e3c', fontSize: isMobile ? '0.9rem' : '1rem' }}>{project.description}</Typography>
                  </CardContent>
                </Card>
              </Box>
            </motion.div>
          )
        ))}
      </AnimatePresence>
    </Box>
  );
};

const FAQ = ({ isMobile }) => {
  const faqs = [
    { question: "What makes your anime-inspired approach unique?", answer: "Our anime-inspired approach combines cutting-edge technology with the creativity and energy of anime culture. This results in innovative solutions that are not only highly functional but also visually stunning and engaging." },
    { question: "Do you work with clients outside of the anime industry?", answer: "Absolutely! While we draw inspiration from anime, our technical expertise is applicable to a wide range of industries. We've successfully completed projects in e-commerce, education, healthcare, and more." },
    { question: "How do you ensure the security of your anime-inspired applications?", answer: "Security is a top priority for us. We implement industry-standard security practices and protocols, including encryption, secure authentication, and regular security audits. Our anime-inspired designs never compromise on security." },
    { question: "Can you integrate AI and machine learning into existing systems?", answer: "Yes, we specialize in integrating AI and ML capabilities into both new and existing systems. Whether it's enhancing a recommendation engine or implementing natural language processing, we can bring cutting-edge AI to your project." },
  ];

  return (
    <Box>
      {faqs.map((faq, index) => (
        <motion.div key={index} variants={fadeInUp} initial="initial" animate="animate">
          <Accordion sx={{ 
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)', 
            borderRadius: 2, 
            marginBottom: 2,
            '&:before': {
              display: 'none',
            },
          }}>
            <AccordionSummary 
              expandIcon={<BsChevronDown style={{ color: '#388e3c' }} />}
              sx={{
                '&.Mui-expanded': {
                  minHeight: 48,
                },
              }}
            >
              <Typography sx={{ color: 'black', fontWeight: 600, fontSize: isMobile ? '0.9rem' : '1rem' }}>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ color: '#388e3c', fontSize: isMobile ? '0.8rem' : '0.9rem' }}>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        </motion.div>
      ))}
    </Box>
  );
};

const FeatureCard = ({ icon: Icon, title, description, isMobile }) => (
  <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
    <Card sx={{ 
      padding: isMobile ? 2 : 3, 
      borderRadius: 2, 
      backgroundColor: '#388e3c', 
      color: 'white', 
      textAlign: 'center', 
      boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}>
      <Icon style={{ width: isMobile ? 36 : 48, height: isMobile ? 36 : 48, marginBottom: isMobile ? 8 : 16 }} />
      <Typography variant={isMobile ? "subtitle1" : "h6"} sx={{ fontWeight: 700, marginBottom: isMobile ? 1 : 2 }}>{title}</Typography>
      <Typography variant="body2" sx={{ fontSize: isMobile ? '0.8rem' : '1rem' }}>{description}</Typography>
    </Card>
  </motion.div>
);

const WhyChooseUs = ({ isMobile }) => {
  const features = [
    { icon: BsLightning, title: "Fast & Reliable", description: "Our solutions are optimized for speed and reliability, ensuring your projects run smoothly and efficiently." },
    { icon: BsShieldCheck, title: "Secure", description: "We prioritize security in all our projects, implementing robust measures to protect your data and systems." },
    { icon: BsPeople, title: "Collaborative", description: "Our team works closely with clients, fostering a collaborative environment to ensure your vision is realized." },
  ];

  return (
    <Grid container spacing={isMobile ? 2 : 4}>
      {features.map((feature, index) => (
        <Grid item xs={12} sm={isMobile ? 12 : 4} key={index}>
          <motion.div variants={fadeInUp}>
            <FeatureCard {...feature} isMobile={isMobile} />
          </motion.div>
        </Grid>
      ))}
    </Grid>
  );
};

const AnimeTechPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <motion.div initial="initial" animate="animate" exit={{ opacity: 0 }}>
      <Header />
      <Welcome />
      <Tech />
      <Container maxWidth="lg" sx={{ paddingTop: isMobile ? 4 : 8, paddingBottom: isMobile ? 4 : 8 }}>
        <motion.div variants={staggerChildren}>
          <motion.div variants={fadeInUp}>
            <Typography variant={isMobile ? "h5" : "h4"} sx={{ marginTop: isMobile ? 4 : 8, marginBottom: isMobile ? 2 : 4, color: '#388e3c', fontWeight: 700, textAlign: 'center' }}>
              Why Choose Us
            </Typography>
            <WhyChooseUs isMobile={isMobile} />
          </motion.div>

          <motion.div variants={fadeInUp}>
            <Typography variant={isMobile ? "h5" : "h4"} sx={{ marginTop: isMobile ? 4 : 8, marginBottom: isMobile ? 2 : 4, color: '#388e3c', fontWeight: 700, textAlign: 'center' }}>
              Our Top Projects
            </Typography>
            <ProjectShowcase isMobile={isMobile} />
          </motion.div>

          <motion.div variants={fadeInUp}>
            <Typography variant={isMobile ? "h5" : "h4"} sx={{ marginTop: isMobile ? 4 : 8, marginBottom: isMobile ? 2 : 4, color: '#388e3c', fontWeight: 700, textAlign: 'center' }}>
              Meet Our Team
            </Typography>
            <Grid container spacing={isMobile ? 2 : 4} justifyContent="center">
              {['Shinji Koyama', 'Aiko Tanaka', 'Hiroshi Yamamoto'].map((name, index) => (
                <Grid item xs={12} sm={isMobile ? 6 : 4} key={index}>
                  <TeamMember
                    name={name}
                    role={['Lead Developer', 'UI/UX Designer', 'AI Specialist'][index]}
                    imageUrl="/api/placeholder/150/150"
                    isMobile={isMobile}
                  />
                </Grid>
              ))}
            </Grid>
          </motion.div>

          <motion.div variants={fadeInUp}>
            <Typography variant={isMobile ? "h5" : "h4"} sx={{ marginTop: isMobile ? 4 : 8, marginBottom: isMobile ? 2 : 4, color: '#388e3c', fontWeight: 700, textAlign: 'center' }}>
              Frequently Asked Questions
            </Typography>
            <FAQ isMobile={isMobile} />
          </motion.div>
        </motion.div>
      </Container>
      <Footer />
    </motion.div>
  );
};

export default AnimeTechPage;