import React from 'react';

const Services = () => {
  return (
    <div className="min-h-screen flex flex-col font-sans text-gray-900">
      <main className="flex-grow">
        <ServicesBanner />
        <ServicesContent />
      </main>
    </div>
  );
};

const ServicesBanner = () => {
  return (
    <div className="bg-green-300 text-white py-20 sm:py-28 text-center">
      <h1 className="text-4xl sm:text-5xl font-extrabold mb-6 px-4 ">
        Services
      </h1>
      <p className="text-xl sm:text-2xl mb-8 px-4 max-w-3xl mx-auto text-black">
        Empowering Your Business with Cutting-Edge Technology Solutions
      </p>
      <button className="bg-white text-black px-8 sm:px-10 py-3 sm:py-4 rounded-full font-semibold shadow-md hover:bg-gray-100 transition duration-300 transform hover:scale-105">
        Explore Our Services
      </button>
    </div>
  );
};

const ServicesContent = () => {
  return (
    <div className="flex justify-center px-4 sm:px-6">
      <section className="z-10 self-center px-6 sm:px-12 py-12 -mt-14 w-full bg-white rounded-3xl max-w-[992px] shadow-lg">
        <div className="flex flex-col sm:flex-row gap-5">
          <div className="w-full sm:w-[26%]">
            <h2 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold text-blue-400 leading-tight sm:leading-[1.2]">
              What We Offer
            </h2>
          </div>
          <div className="w-full sm:w-[74%] mt-6 sm:mt-0">
            <div className="flex flex-col w-full text-lg sm:text-xl text-zinc-700">
              <p className="mb-4">
                Our comprehensive suite of technology solutions is designed to propel your business forward in the digital age. From custom software development to cutting-edge AI implementations, we offer:
              </p>
              <ul className="list-disc list-inside mb-4 space-y-2">
                <li>Tailored Software Solutions</li>
                <li>AI and Machine Learning Integration</li>
                <li>Cloud Migration and Management</li>
                <li>Cybersecurity Services</li>
                <li>Data Analytics and Business Intelligence</li>
              </ul>
              <p>
                Partner with us to transform your business challenges into opportunities for growth and innovation. Our team of experts is ready to craft the perfect solution for your unique needs.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;