import React from 'react';
import { Smartphone, CheckCircle, Users } from 'lucide-react';

const InnovationMission = () => {
  return (
    <div className="bg-gray-50  text-yellow-300 min-h-screen flex items-center justify-center p-4 sm:p-8">
      <div className="max-w-5xl w-full bg-white bg-opacity-50 rounded-xl shadow-2xl p-6 sm:p-10">
        <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-green-300 to-green-500">
          We innovate for better lives
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[
            { icon: Smartphone, title: "Providing education", subtitle: "for future innovators" },
            { icon: CheckCircle, title: "Reducing our", subtitle: "carbon footprint" },
            { icon: Users, title: "Building inclusive", subtitle: "communities" }
          ].map((item, index) => (
            <div key={index} className="bg-orange-100 bg-opacity-50 rounded-lg p-6 transform transition duration-300 hover:scale-105 hover:bg-opacity-70">
              <div className="flex flex-col items-center text-center">
                <item.icon className="w-16 h-16 mb-4 text-black" />
                <h2 className="text-xl text-orange-500 font-semibold mb-2">{item.title}</h2>
                <p className="text-black">{item.subtitle}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = `
  @keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }

  .bg-gradient-to-br {
    background-size: 200% 200%;
    animation: gradientAnimation 15s ease infinite;
  }
`;

const InnovationMissionWithStyles = () => (
  <>
    <style>{styles}</style>
    <InnovationMission />
  </>
);

export default InnovationMissionWithStyles;