import React, { useEffect } from 'react';
import { fadeInUp, fadeInLeft, fadeInRight } from './animations';
import Header from './ABOUT/Header';
import HeroSection from './ABOUT/Welcome';
import AboutSection from './ABOUT/AboutUs';
import ServicesSection from './ABOUT/WhyWeChooseUs';
import Footer from './home/Footer';

import Partner from './home/Partners';
const Home = () => {
  useEffect(() => {
    fadeInUp();
    fadeInLeft();
    fadeInRight();
  }, []);

  return (
    <div className="font-sans">
      <Header />
      <main className="container mx-auto px-4 pt-20">
        <HeroSection />
        <AboutSection />
        <ServicesSection />
       
        < Partner/>
      </main>
      <Footer />
    </div>
  );
};

export default Home;
