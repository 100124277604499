import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import './Welcome.css';

const Welcome = () => {
  const [displayText, setDisplayText] = useState("Welcome to Ravuru Tech");
  const controls = useAnimation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayText("Accelerating our company Through Innovative IT solutions");
      controls.start("visible");
    }, 3000);

    return () => clearTimeout(timer);
  }, [controls]);

  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 1,
        ease: "easeOut"
      }
    }
  };

  const lineVariants = {
    hidden: { opacity: 0, scaleX: 0 },
    visible: { 
      opacity: 1, 
      scaleX: 1,
      transition: { 
        duration: 0.5,
        ease: "easeOut",
        delay: 0.2
      }
    }
  };

  const buttonVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
        delay: 0.4
      }
    },
    hover: {
      scale: 1.05,
      transition: { duration: 0.3 }
    },
    tap: { scale: 0.95 }
  };

  return (
    <section className="hero-section flex flex-col justify-center items-center relative min-h-screen overflow-hidden text-center py-12">
      <motion.h1 
        variants={textVariants}
        initial="hidden"
        animate="visible"
        className="text-4xl md:text-6xl font-bold mb-4 text-gray-800"
      >
        {displayText}
      </motion.h1>
      <motion.div 
        className="flex justify-center space-x-2 mb-8"
        variants={lineVariants}
        initial="hidden"
        animate="visible"
      >
        <div className="w-12 h-1 bg-orange-500"></div>
        <div className="w-12 h-1 bg-orange-500"></div>
      </motion.div>
      <motion.button
        variants={buttonVariants}
        initial="hidden"
        animate="visible"
        whileHover="hover"
        whileTap="tap"
        className="bg-[#2ecc71] text-white px-6 py-2 rounded hover:bg-[#11c25b] transition-colors duration-300"
      >
        Explore Our Solutions
      </motion.button>
     
      <div className="absolute inset-0 pointer-events-none">
        <div className="background-overlay"></div>
      </div>
    </section>
  );
};

export default Welcome;