import React from 'react';
import { motion } from 'framer-motion';

const Technology = () => {
  return (
    <div className="min-h-screen flex flex-col font-sans text-gray-900">
      <main className="flex-grow">
        <TechnologyBanner />
        <TechnologyContent />
      </main>
    </div>
  );
};

const TechnologyBanner = () => {
  return (
    <div className="bg-[#F7DC6F] text-white py-20 sm:py-28 text-center">
      <motion.h1
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-4xl sm:text-5xl font-extrabold mb-6 px-4"
      >
        Our Technologies
      </motion.h1>
      <motion.p
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
        className="text-xl sm:text-2xl mb-8 px-4 max-w-3xl mx-auto text-black"
      >
        Explore the cutting-edge technologies powering our innovative solutions
      </motion.p>
      <motion.button
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.5 }}
        className="bg-white text-black px-8 sm:px-10 py-3 sm:py-4 rounded-full font-semibold shadow-md hover:bg-gray-100 transition duration-300 transform hover:scale-105"
      >
        Explore Our Tech Stack
      </motion.button>
    </div>
  );
};

// import React from 'react';
// import { motion } from 'framer-motion';

const TechnologyContent = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  return (
    <div className="flex justify-center px-4 sm:px-6 lg:px-8 bg-gray-50 min-h-screen py-16">
      <motion.section
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        className="z-10 self-center px-6 sm:px-12 py-12 w-full bg-white rounded-3xl max-w-[1200px] shadow-2xl overflow-hidden"
      >
        <div className="flex flex-col lg:flex-row gap-8 lg:gap-16">
          <motion.div 
            className="w-full lg:w-1/3"
            variants={itemVariants}
          >
            <h2 className="text-4xl sm:text-5xl lg:text-6xl font-bold text-gray-800 leading-tight mb-6 lg:mb-0 lg:sticky lg:top-6">
              Our
              <span className="block text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-indigo-600">
                Tech Stack
              </span>
            </h2>
          </motion.div>
          <div className="w-full lg:w-2/3">
            <motion.div
              variants={itemVariants}
              className="text-lg sm:text-xl text-gray-700 space-y-8"
            >
              <p className="leading-relaxed">
                At the core of our solutions lies a robust and versatile tech stack, carefully chosen to meet the diverse needs of our clients and projects. We leverage cutting-edge technologies to deliver exceptional results.
              </p>
              
              <TechSection 
                title="Frontend Technologies"
                items={[
                  "React.js for building dynamic and responsive user interfaces",
                  "Next.js for server-side rendering and optimized performance",
                  "Tailwind CSS for rapid and customizable styling",
                  "Framer Motion for fluid animations and transitions"
                ]}
                itemVariants={itemVariants}
              />
              
              <TechSection 
                title="Backend Technologies"
                items={[
                  "Node.js and Express for scalable server-side applications",
                  "Python with Django for robust web frameworks",
                  "GraphQL for efficient data querying and manipulation",
                  "MongoDB and PostgreSQL for flexible data storage solutions"
                ]}
                itemVariants={itemVariants}
              />
              
              <motion.p 
                variants={itemVariants}
                className="text-gray-700 italic border-l-4 border-indigo-500 pl-4"
              >
                Our commitment to staying at the forefront of technological advancements ensures that we deliver cutting-edge solutions tailored to each client's unique requirements.
              </motion.p>
            </motion.div>
          </div>
        </div>
      </motion.section>
    </div>
  );
};

const TechSection = ({ title, items, itemVariants }) => {
  return (
    <motion.div variants={itemVariants} className="space-y-4">
      <h3 className="text-2xl font-semibold text-gray-800 flex items-center">
        <span className="mr-3 text-indigo-500">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z" />
          </svg>
        </span>
        {title}
      </h3>
      <ul className="space-y-3">
        {items.map((item, index) => (
          <motion.li 
            key={index}
            className="flex items-start"
            variants={itemVariants}
          >
            <span className="text-indigo-500 mr-2">•</span>
            <span>{item}</span>
          </motion.li>
        ))}
      </ul>
    </motion.div>
  );
};


export default Technology;