import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, Sun, Cloud, Users } from 'lucide-react';
import { Link } from 'react-router-dom';

const services = [
  {
    title: 'AI Based Solution',
    description: 'Take a controlled plunge into AI with our Proof-of-Concept (POC) services, assessing project feasibility before full-scale investment.',
    path: '/service1',
    icon: <Sun className="w-10 h-10 text-yellow-600" />,
    color: 'bg-yellow-100',
    hoverColor: 'bg-yellow-200',
  },
  {
    title: 'Launch Your AI Vision with a MVP',
    description: 'Craft Minimum Viable Products (MVPs) to bring cutting-edge AI products to market quickly and effectively.',
    path: '/service2',
    icon: <Cloud className="w-10 h-10 text-green-600" />,
    color: 'bg-green-100',
    hoverColor: 'bg-green-200',
  },
  {
    title: 'Power Up Your Apps with AI',
    description: 'Develop AI-driven web or mobile apps that captivate users and turn your vision into reality.',
    path: '/service3',
    icon: <Users className="w-10 h-10 text-orange-600" />,
    color: 'bg-orange-100',
    hoverColor: 'bg-orange-200',
  },
];

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 100,
    },
  },
};

const ServiceCard = ({ service, index }) => (
  <motion.div
    variants={itemVariants}
    whileHover={{
      scale: 1.05,
      zIndex: 20,
      transition: { duration: 0.3 },
    }}
    className={`${service.color} hover:${service.hoverColor} rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300 p-6 md:p-8 relative cursor-pointer w-full md:w-[calc(100%-30px)] mx-auto md:mx-0`}
    style={{
      marginTop: `${index * 50}px`,
      zIndex: 10 - index,
      transform: `translateX(${index % 2 === 0 ? '-20%' : '20%'})`,
    }}
  >
    <div className="flex items-center mb-4">
      <div className="p-2 rounded-full bg-white shadow-md">
        {service.icon}
      </div>
      <h3 className="text-xl font-bold text-gray-800 ml-4">{service.title}</h3>
    </div>
    <p className="text-gray-600 mb-6 text-sm md:text-base leading-relaxed">{service.description}</p>
    <Link
      to={service.path}
      className="inline-flex items-center text-gray-800 font-semibold hover:text-yellow-600 transition-colors duration-300 text-sm md:text-base"
    >
      Learn More
      <motion.span
        className="ml-2"
        initial={{ x: 0 }}
        whileHover={{ x: 5 }}
        transition={{ type: 'spring', stiffness: 300 }}
      >
        <ArrowRight className="h-5 w-5" />
      </motion.span>
    </Link>
  </motion.div>
);

const ServicesComponent = () => {
  return (
    <div className="bg-gradient-to-br bg-gray-50 min-h-screen py-12 px-6 md:px-12 overflow-hidden">
      <div className="max-w-6xl mx-auto text-center mb-12">
        <motion.h1
          className="text-4xl md:text-5xl font-extrabold text-gray-900 mb-4 bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 via-green-500 to-orange-500"
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: 'easeOut' }}
        >
          Our Approach
        </motion.h1>
        <motion.p
          className="text-lg md:text-xl text-gray-600"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.2, ease: 'easeOut' }}
        >
          Discover how our services work together to elevate your business.
        </motion.p>
      </div>
      
      <div className="relative max-w-4xl mx-auto">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="flex flex-col items-center"
        >
          {services.map((service, index) => (
            <ServiceCard key={index} service={service} index={index} />
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default ServicesComponent;
