import React, { useState } from 'react';
import { Target, Laptop, Users, AlertCircle } from 'lucide-react';
import './About.css';
const Feature = ({ title, description, icon: Icon, color }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <div 
      className={`feature-card ${color}`}
      onMouseEnter={() => setIsFlipped(true)}
      onMouseLeave={() => setIsFlipped(false)}
    >
      <div className={`card-inner ${isFlipped ? 'flipped' : ''}`}>
        <div className="card-front">
          <Icon size={60} />
          <h3>{title}</h3>
        </div>
        <div className="card-back">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

const Features = () => (
  <div className="features-container">
    <h2 className="about-title" style={{ color: '#005C53' }}>What Special About Ravuru Tech </h2>

    <div className="features-grid">
      <Feature
        title="Founded 2023"
        description=" It was established in 2023 by a passionate team of tech enthusiaste in Begalur, India.They combined thier expertise in web developmemt, machine learning, AI,and mobile app development to brin thier vision to life, From the outset,thier goal was to create a company that would not omly deliver exceptional results but also faster a culture of innovation and collaboration."
        icon={Target}
        color="bg-yellow-100"
      />
      <Feature
        title="Our story "
        description="A small seed of ambition, nurtured by a passion for IT, has blossomed into RAVURU TECH.Our journey began with a passionate team, and through steady growth, we've evolved into a comprehensive IT solutions provider. At the heart of everything we do lies a commitment to understanding your unique needs and crafting bespoke solutions to fuel your success."
        icon={Laptop}
        color="bg-green-100"
      />
      <Feature
        title="Our Expertise"
        description="While our heritage lies in the bedrock of IT services like cloud computing, network management, and cybersecurity, at RAVURU TECH, we've transcended mere problem-solving. We're architects of the future, building revolutionary AI products fueled by relentless R&D."
        icon={Users}
        color="bg-blue-100"
      />
      <Feature
        title="More"
        description="Discover additional information about our services, team, and success stories."
        icon={AlertCircle}
        color="bg-purple-100"
      />
    </div>
    <style jsx>{`
      
    `}</style>
  </div>
);

export default Features;
