import React, { useState, useEffect } from 'react';
import { X, Menu } from 'lucide-react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const links = [
    { name: 'Home', path: '/' },
    { name: 'About', path: '/about' },
    { name: 'Career', path: '/career' },
    { name: 'Services', path: '/services' },
    { name: 'Technologies', path: '/technologies' }
  ];

  return (
    <motion.header
      className={`flex justify-between items-center fixed p-4 top-0 left-0 right-0 z-50 no-underline transition-all duration-100 ${
        scrolled ? 'bg-white shadow-lg h-16 md:h-20' : 'bg-white shadow-lg h-16 md:h-20'
      }`}
    >
      <motion.div
        whileHover={{ scale: 1.05 }}
        className="text-2xl md:text-3xl font-bold text-green-600 hover:text-orange-500 transition-colors duration-300 no-underline"
      >
        <Link to="/">Ravuru Tech</Link>
      </motion.div>
      <nav className="hidden md:flex space-x-8 animate-fadeInRight">
        {links.map((link, index) => (
          <motion.div
            key={index}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            <Link
              to={link.path}
              className={`flex items-center text-gray-600 hover:text-green-500 transition-colors duration-300 no-underline ${
                link.name === 'Services' ? 'text-green-500' : ''
              }`}
            >
              {link.name}
            </Link>
          </motion.div>
        ))}
      </nav>
      <motion.div
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        className="hidden md:block"
      >
        <Link
          to="/contact-us"
          className="px-4 py-2 bg-orange-400 text-white rounded-full hover:bg-orange-600 transition-colors duration-300 no-underline"
        >
          Contact Us
        </Link>
      </motion.div>
      <div className="md:hidden">
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          className="text-gray-600 hover:text-orange-500 transition-colors duration-300"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <Menu size={24} />
        </motion.button>
      </div>
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ type: 'tween', duration: 0.3 }}
            className="fixed inset-y-0 right-0 w-64 bg-white z-20 flex flex-col items-start p-4 space-y-4 shadow-lg"
          >
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className="self-end p-2 text-gray-600 hover:text-orange-500 transition-colors duration-300"
              onClick={() => setIsMenuOpen(false)}
            >
              <X size={24} />
            </motion.button>
            {[...links, { name: 'Contact Us', path: '/contact-us' }].map((link, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="w-full"
              >
                <Link
                  to={link.path}
                  className={`block py-2 text-gray-600 hover:text-green-500 transition-colors duration-300 text-lg no-underline ${
                    link.name === 'Services' ? 'text-green-500' : ''
                  }`}
                  onClick={() => setIsMenuOpen(false)}
                >
                  {link.name}
                </Link>
              </motion.div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.header>
  );
};

export default Header;
