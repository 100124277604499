import React, { useEffect } from 'react';
import { fadeInUp, fadeInLeft, fadeInRight } from './animations';
import Header from './home/Header';
import HeroSection from './home/Welcome';
import AboutSection from './home/AboutUs';
import ServicesSection from './home/Services';
import Footer from './home/Footer';
import Technologies from './home/Technologies';
// import ChooseUs from './ChooseUs';
import Partner from './home/Partners';
import Innovation from './home/Innovation';
import BlogList from './home/Blog';
const Home = () => {
  useEffect(() => {
    fadeInUp();
    fadeInLeft();
    fadeInRight();
  }, []);

  return (
    <div className="font-sans">
      <Header />
      <main className="container mx-auto px-4 pt-20">
        <HeroSection />
        <AboutSection />
        <ServicesSection />
        <Technologies/>
        <Innovation/>
        {/* < ChooseUs/> */}
        <BlogList/>
        < Partner/>
      </main>
      <Footer />
    </div>
  );
};

export default Home;
